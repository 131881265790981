import { useEffect } from 'react';

import { cookie } from '@tager/web-core';

function RemoveCookieElement() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      cookie.set('utm', '', undefined, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
}

export default RemoveCookieElement;
